<template>
  <v-form class="profile-form">
    <div class="working-days">
      <template v-if="loading">

        <v-progress-linear
          indeterminate
        ></v-progress-linear>

      </template>
      <template v-else>
        <div class="working-days__head">Мои рабочие дни</div>
        <template v-for="item in collectedDayList">
          <WorkingDay
            :form-data.sync="item.data"
            :value="item.key"
            :label="item.value"
            :key="item.key"
          />
        </template>
      </template>
    </div>
  </v-form>
</template>

<script>

import WorkingDay from '@component/Page/Profile/WorkingDay';
import { mapState } from 'vuex';

export default {
  components: {
    WorkingDay,
  },
  data() {
    return {
      loading: true,
      daysOfWeek: [],
      workingDays: [],
      collectedDayList: []
    };
  },
  watch: {
    collectedDayList: {
      deep: true,
      handler: function (dayList)  {
        for (let day of dayList) {
          if (day.data?.is_active) {
            this.$emit('update:valid', true);
            break;
          }
          this.$emit('update:valid', false);
        }
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
  },
  async mounted() {
    Promise.all([
      RequestManager.Timetable.getDays(this.user.id),
      RequestManager.Timetable.getList(this.user.id)
    ])
      .then(([ daysOfWeek, workingDays]) => {
        this.daysOfWeek = daysOfWeek;
        this.workingDays = workingDays;

        this.collectedDayList = this.daysOfWeek.reduce((result, day) => {
          let workingDay = this.workingDays.find(({weekday}) => weekday === day.key);

          result.push({
            ...day,
            data: workingDay ?? {
              is_active: false,
              start_time: '',
              end_time: '',
              weekday: day.key
            }
          });

          return result;
        }, []);

      })
      .finally(() => this.loading = false);

  }
};

</script>

<style lang="scss" scoped>

// working-days
.working-days {
  margin-bottom: 40px;
  background: #FFFFFF;
  border: 1px solid rgba(118, 118, 128, 0.2);
  border-radius: 8px;
  .working-days__head {
    padding: 24px 26px 28px;
    color: #000000;
    font-weight: 600;
    font-size: 19px;
    border-bottom: 1px solid rgba(118, 118, 128, 0.2);
  }
}

</style>
