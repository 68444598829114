<template>
  <div
    class="working-day"
    v-click-outside="hideEdit"
    @click="showEdit"
  >
    <div class="working-day__block">

      <div class="working-day__label">{{ label }}</div>
      <template v-if="active">
        <v-form v-model="valid" ref="form">
          <div class="working-day__edit">
            <div class="field-ell--inline">

              <TextInput
                v-mask="`##:##`"
                label="Работаю с"
                v-model.lazy="formData.start_time"
                placeholder="ЧЧ:ММ"
                :readonly="formData.is_active"
                required
                hide-details
                is-time
                ref="time"
                @click="onFieldClick"
              />

            </div>
            <div class="field-ell--inline">

              <TextInput
                label="до"
                v-mask="`##:##`"
                v-model.lazy="formData.end_time"
                placeholder="ЧЧ:ММ"
                required
                :readonly="formData.is_active"
                hide-details
                is-time
                :rules="[
                  value => this.compareTime() || 'Время завершеняя должно быть больше чем время начала'
                ]"
                @click="onFieldClick"
              />

            </div>
            <div class="working-day__checkbox" @click="onClick">
              <input
                class="working-day__field"
                type="checkbox"
                :checked="formData.is_active"
              />
              <div class="working-day__vue" ref="checkbox">
                <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="formData.is_active" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.021 6.41L8.785.646l.736.762-6.5 6.5L0 4.887l.762-.762 2.26 2.285z" fill="#0C72D6"/></svg>
              </div>
            </div>
          </div>
        </v-form>
      </template>
      <template v-if="formData.is_active && !active">

        <div class="working-day__preview">

          <span class="working-day__descr">С {{ formData.start_time | formatTime }} до {{ formData.end_time | formatTime }}</span>
          <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.021 6.41L8.785.646l.736.762-6.5 6.5L0 4.887l.762-.762 2.26 2.285z" fill="#0C72D6"/></svg>

        </div>

      </template>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import TextInput from "@component/UI/Form/TextInput";

export default {
  components: {
    TextInput,
  },
  model: {
    event: 'input',
    prop: 'formData'
  },
  props: {
    label: String,
    value: [String, Number],
    formData: Object
  },
  data() {
    return {
      valid: false,
      active: false,
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
  },

  methods: {
    onFieldClick() {
      if (!this.formData.is_active) return;
      const checkbox = this.$refs.checkbox;
      checkbox.classList.add('error--text');
      setTimeout(() => {
        checkbox.classList.remove('error--text');
      }, 750);
    },
    async onClick() {
      await this.$refs.form.validate();

      if (!this.valid) {
        this.formData.is_active = false;
        return;
      }

      this.formData.is_active = !this.formData.is_active;

      if (this.formData.is_active && !this.formData?.id) {
        const formData = await RequestManager.Timetable.create(this.user.id, this.formData);
        this.formData.id = formData.id;
      } else if (this.formData?.id) {
        RequestManager.Timetable.update(this.user.id, this.formData.id, this.formData);
      }

    },
    compareTime() {
      let { start_time, end_time } = this.formData;

      if (start_time.length < 5 || end_time.length < 5) return false;

      start_time = start_time.split(':');
      end_time = end_time.split(':');

      let fromTime = DateTime().hour(start_time[0]).minute(start_time[1]);
      let toTime = DateTime().hour(end_time[0]).minute(end_time[1]);

      let interval = toTime.diff(fromTime, "minute");

      return interval > 0;
    },
    showEdit() {
      this.active = true;
    },
    hideEdit() {
      this.active = false;
    }
  },
};

</script>

<style lang="scss" scoped>

// working-day
.working-day {
  border-bottom: 1px solid rgba(118, 118, 128, 0.2);
  &:last-child {
    border-bottom: none;
  }
  .working-day__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: 54px;
    padding: 4px 26px;
    cursor: pointer;
    @include respond-to('sm') {
      flex-wrap: nowrap;
    }
    .working-day__label {
      color: #000000;
      font-size: 19px;
      //padding: 20px;
    }
    .working-day__preview {
      .working-day__descr {
        margin-right: 12px;
        color: rgba(0, 0, 0, 0.4);
        font-size: 15px;
      }
      svg {
        width: 18px;
        height: auto;
        path {
          fill: #35C759;
        }
      }
    }
  }
  .working-day__edit {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 15px;
    @include respond-to('sm') {
      flex-wrap: nowrap;
      margin-top: 0;
    }
    .field-ell--inline {
      width: 100%;
      margin-bottom: 15px;
      @include respond-to('sm') {
        margin-bottom: 0;
        margin-right: 22px;
      }
      ::v-deep .v-input {
        &.error--text {
          input {
            border: 1px solid red;
          }
        }
        .v-input__control {
          margin-bottom: 0;
          .v-input__slot {
            min-height: 36px;
            margin-bottom: 0;
            border-bottom: none;
            &:after {
              display: none;
            }
            .v-text-field__slot {
              height: 36px;
              .v-label {
                position: static !important;
                margin-right: 12px;
                padding-bottom: 0;
                color: #000000;
                font-size: 17px;
                font-weight: 600;
              }
              input {
                max-width: 76px;
                max-height: 36px;
                color: #000000;
                font-size: 19px;
                text-align: center;
                background-color: rgba(118, 118, 128, 0.12);
                border-radius: 6px;
                @include respond-to('sm') {
                  max-width: 100%;
                  width: 76px;
                }
                &[readonly] {
                  color: #8f8f8f;
                }
              }
            }
          }
        }
      }
    }
  }
  .working-day__checkbox {
    position: relative;
    margin-bottom: 15px;
    display: none;
    @include respond-to('sm') {
      margin-bottom: 0;
      display: block;
    }
    .working-day__vue {
      display: none;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border-radius: 6px;
      border: 1px solid rgba(118, 118, 128, 0.2);
      background-color: #FFFFFF;
      transition: .25s linear;
      @include respond-to('sm') {
        display: flex;
      }
      &.error--text {
        border-color: red;
      }
      svg {
        width: 20px;
        height: auto;
        transform: rotateX(90deg) scale(0.5);
        transition: all .2s ease-in-out;
        path {
          fill: #35C759;
        }
      }
    }
    .working-day__field {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 2;
      &:checked + .working-day__vue {
        svg {
          transform: rotateX(0deg) scale(1);
        }
      }
    }
  }
}

</style>
